import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo from "../assets/images/IMG-20221121-WA0004.jpg";
import AcademicPlannerPdf from "../assets/pdf/Academic-Planner.pdf";
import http from '../_api/http';
import twitterLogo from "../assets/images/social_media/twitter.jpg";
import facebookLogo from "../assets/images/social_media/facebook.png";
import youtubeLogo from "../assets/images/social_media/youtube.jpeg";
import instaLogo from "../assets/images/social_media/instagram.jpeg";
import whatsappLogo from "../assets/images/whatsapp_img.png";
import NewsLetter from './NewsLetter';

const Footer = () => {
  function scrollTop() {
    window.scrollTo(0, 0);
  }
  const knowledge1 = require('../assets/images/Knowledge Parner Logo/Catalyzer.png');
  const knowledge2 = require('../assets/images/Knowledge Parner Logo/Franklin Covey.png');
  const knowledge3 = require('../assets/images/Knowledge Parner Logo/Keystone Global.svg');
  const knowledge4 = require('../assets/images/Knowledge Parner Logo/Leader In Me.png');
  const knowledge5 = require('../assets/images/Knowledge Parner Logo/SPACEWALK EDUCATION LOGO.jpeg');

  const [loading, setLoading] = useState(true);
  const [images, setImage] = useState([]);
  const [explorerPdfList, setexplorerPdfList] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;
  const logoStyle = {
    height: "22px",
    width: "22px",
    borderRadius: "5px"
  }
  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadGallaryList?image_subcategory_id=17&section=Photo').then((res) => {
      // alert(res);
      // debugger
      setImage(res.data);
      setLoading(false);
    });

    http
      .get(
        'api/pdf/ApiPdfList?pdf_for_type=21'
      )
      .then(res => {
        // alert(res);
        // debugger
        console.log(res);
        if (res.data.status === 200) {
          setexplorerPdfList(res.data.pdf);
          // setLoading(false);
        }
      })
  }, []);
  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-12">

                <div className="footer-widget footer-widget__contact">
                  <img className="mb-2" src={logo} width={190} />
                  <br />
                  <h2 className="footer-widget__title titles"> Shanti Asiatic School</h2>
                  <ul className="list-unstyled footer-widget__course-list">
                    <li>
                      <h2 className="footer-widget__title titles">
                        <a href="course-details.html">
                          +91 75674 00000
                        </a>
                      </h2>
                    </li>
                    <li>
                      <h2>
                        <a href="course-details.html">
                          {" "}
                          http://shantiasiaticsurat.com{" "}
                        </a>
                      </h2>
                      <h2>
                        <a href="whatsapp://send?abid=9909998354&text=Hello%2C%20World!"><img style={{ height: "20px", width: "20px" }} src={whatsappLogo} alt="Whatsapp" />9909998354</a>
                      </h2>
                      <p>Block No. 421-427, Olpad-Dandi Road, New Narthan Town, Taluka: Olpad, District : Surat 395 005</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__link">
                  <h2 className="footer-widget__title">Explore</h2>
                  <div className="footer-widget__link-wrap">
                    <ul className="list-unstyled footer-widget__link-list">
                      <li>
                        <a href={AcademicPlannerPdf} target="_blank"
                          rel="noreferrer">
                          Academic Planner
                        </a>
                      </li>
                      <li>
                        <a href="/cocurricular1">Co-Curricular</a>
                      </li>
                      <li>
                        <a href="/sport">Sports</a>
                      </li>
                      <li>
                        <a href="/amd">Fine Arts</a>
                      </li>
                      <li>
                        <a href="/infrastructure">Infrastructure</a>
                      </li>
                      <li>
                        <a href="/transfercertificate">Transfer Certificate</a>
                      </li>
                      {explorerPdfList && explorerPdfList.map((item, i) => (
                        <li key={i} >
                          <a
                            href={PUBLIC_URL + 'files/' + item.name}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {item.pdf_select_for}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div className="row">
                      <ul className="list-unstyled footer-widget__link-list">
                        <li>
                          <a href="/admission">Admission Form  </a>
                        </li>
                        <li>
                          <a href="/contact">Help</a>
                        </li>
                        <li>
                          <a href="/career">Career</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__gallery">
                  <h2 className="footer-widget__title" style={{ textAlign: "right" }}>Knowledge Partner</h2>
                  <div className="footer-widget__link-wrap">
                    <ul className="list-unstyled footer-widget__link-list">
                      <li>
                        <img src={knowledge1} className="knowledgeImg"></img>
                      </li>
                      <li>
                        <img src={knowledge2} className="knowledgeImg"></img>
                      </li>
                      <li>
                        <img src={knowledge3} className="knowledgeImg"></img>
                      </li>
                      <li>
                        <img src={knowledge4} className="knowledgeImg"></img>
                      </li>
                      <li>
                        <img src={knowledge5} className="knowledgeImg"></img>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__about">
                  <h2 className="footer-widget__title">About</h2>
                  <p className="footer-widget__text">
                    We, at Shanti Asiatic School Surat, believe education should be holistic and progressive that
                    transforms a child to become a successful global citizen of tomorrow. Hence, our approach and
                    outlook is a blend of values with a futuristic vision.
                  </p>
                  <div className="footer-widget__btn-block">
                    <a href="/contact" className="thm-btn">
                      Contact Us
                    </a>
                    {/* <a href="#none" className="thm-btn">
                      Purchase
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <p className="site-footer__copy">
              &copy; Copyright 2022 by <a href="#none">emoiss.in</a>
            </p>
            <div className="site-footer__social">
              <div
                onClick={scrollTop}
                onKeyDown={scrollTop}
                role="button"
                tabIndex={0}
                className="scroll-to-target site-footer__scroll-top"
              >
                <i className="kipso-icon-top-arrow"></i>
              </div>

              <a href='https://twitter.com/suratsas'>
                <img style={logoStyle} src={twitterLogo} alt="twitter" />
              </a>
              <a href='https://www.facebook.com/profile.php?id=100093837762077&mibextid=ZbWKwL'>
                <img style={logoStyle} src={facebookLogo} alt="facebook" />
              </a>
              <a href='https://www.youtube.com/channel/UChJ3gkcAe2V8fwM2xjV4yYg'>
                <img style={logoStyle} src={youtubeLogo} alt="youtube" />
              </a>
              <a href='https://www.instagram.com/shanti_asiatic_school_surat/'>
                <img style={logoStyle} src={instaLogo} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* <div className="banner-carousel-btn">
        <div
          onClick={goPrev}
          onKeyUp={goPrev}
          tabIndex={0}
          role="button"
          className="banner-carousel-btn__left-btn banner-arrow"
        >
          <i className="kipso-icon-left-arrow"></i>
        </div>
        <div
          onClick={goNext}
          onKeyDown={goNext}
          role="button"
          tabIndex={0}
          className="banner-carousel-btn__right-btn banner-arrow"
        >
          <i className="kipso-icon-right-arrow"></i>
        </div>
      </div> */}

      <div className="search-popup">
        <div className="search-popup__overlay custom-cursor__overlay search-overlay"></div>
        <div className="search-popup__inner">
          <form action="#" className="search-popup__form">
            <input
              type="text"
              name="search"
              placeholder="Type here to Search...."
            />
            <button type="submit">
              <i className="kipso-icon-magnifying-glass"></i>
            </button>
            <div className="cancel"></div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Footer;
